import {
  API_DASH_GET_USER_LIST
} from '@/constants/apis'
export const fileRegistrationData = [{
  parent: 'fileRegistrationData',
  title: 'Biaya Pendaftaran',
  fields: 'registration_fee',
  value: '',
  validation: {
    required: true,
    isMoney: true
  },
  type: 'number',
  order: 1,
  group: 1,
// }, {
//   parent: 'fileRegistrationData',
//   title: 'Formulir Kesehatan (file PDF)',
//   fields: 'health_file_url',
//   value: [],
//   isFormPdf: true,
//   validation: {
//     required: true,
//     maxSized: true,
//     accept: '.pdf',
//     isPdf: true
//   },
//   type: 'file',
//   order: 2,
//   group: 2,
}, {
  parent: 'fileRegistrationData',
  title: 'Form Dukungan Finansial S.Th (file PDF)',
  fields: 'sth_financial_file_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 3,
  group: 2,
},
// {
//   parent: 'fileRegistrationData',
//   title: 'Form Dukungan Finansial M.Div (file PDF)',
//   fields: 'mdiv_financial_file_url',
//   value: [],
//   isFormPdf: true,
//   validation: {
//     required: true,
//     maxSized: true,
//     accept: '.pdf',
//     isPdf: true
//   },
//   type: 'file',
//   order: 4,
//   group: 3
// }, 
{
  parent: 'fileRegistrationData',
  title: 'Form Dukungan Finansial M.Min (file PDF)',
  fields: 'mmin_financial_file_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 5,
  group: 2,
}, {
  parent: 'fileRegistrationData',
  title: 'Form Dukungan Finansial M.Th S1 Teologi (file PDF)',
  fields: 'mth_financial_file_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 6,
  group: 2,
}, {
  parent: 'fileRegistrationData',
  title: 'Form Dukungan Finansial M.Th S1 Umum (file PDF)',
  fields: 'mthu_financial_file_url',
  value: [],
  isFormPdf: true,
  validation: {
    required: false,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 7,
  group: 2,
}, {
  parent: 'fileRegistrationData',
  title: 'Form Dukungan Finansial Sertifikat Teologi (file PDF)',
  fields: 'sert_financial_file_url',
  isFormPdf: true,
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 8,
  group: 2,
}, {
  parent: 'fileRegistrationData',
  title: 'Panduan Esai Program Studi Magister Teologi (M.Th.) (file PDF)',
  fields: 'guide_article_url',
  isFormPdf: true,
  value: [],
  validation: {
    required: true,
    maxSized: true,
    accept: '.pdf',
    isPdf: true
  },
  type: 'file',
  order: 9,
  group: 3,
}]

export const lecturer = [{
  parent: 'lecturer',
  title: 'Dosen ke-1',
  fields: 'user_name_1',
  value: '',
  name: '',
  id: '',
  validation: {
    required: true,
  },
  type: 'autoComplete',
  apiUrl: API_DASH_GET_USER_LIST,
  params: {
    page: 1,
    limit: 100,
    role: 'dosen'
  },
  option: [],
  order: 1,
  group: 1,
}, {
  parent: 'lecturer',
  title: 'Dosen ke-2',
  fields: 'user_name_2',
  value: '',
  name: '',
  id: '',
  validation: {
    required: true,
  },
  type: 'autoComplete',
  apiUrl: API_DASH_GET_USER_LIST,
  params: {
    page: 1,
    limit: 100,
    role: 'dosen'
  },
  option: [],
  order: 2,
  group: 1,
}, {
  parent: 'lecturer',
  title: 'Dosen ke-3',
  fields: 'user_name_3',
  value: '',
  name: '',
  id: '',
  validation: {
    required: true,
  },
  type: 'autoComplete',
  apiUrl: API_DASH_GET_USER_LIST,
  params: {
    page: 1,
    limit: 100,
    role: 'dosen'
  },
  option: [],
  order: 3,
  group: 1,
}]