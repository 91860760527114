<template>
  <div class="evaluation-setting">
    <v-container>
      <div class="main-container">
        <v-card
          rounded="lg"
          class="pa-9"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <v-row no-gutters class="general-form">
            <v-col
              cols="12"
              sm="2"
              md="2"
              lg="2"
              class="general-form__col"
            >
              <div class="mr-4 mb-4">
                <v-img
                  :src="profileImage"
                  width="140"
                  height="auto"
                  lazy-src="@/assets/imgs/icon-profile.png"
                  @error="handleImage($event)"
                ></v-img>
              </div>
            </v-col>
            <v-col
              cols="12"
              sm="7"
              md="7"
              lg="7"
              class="general-form__col"
            >
              <h2 class="mb-4">{{ 'dash.student.detail.title' | text }}</h2>
              <v-row 
                no-gutters
                class="mb-4"
                v-for="item in detailList"
                :key="item.title"
              >
                <v-col
                  cols="4"
                  sm="4"
                  md="4"
                  lg="3"
                >
                  <p class="title-14">{{ item.title | text }}</p>
                </v-col>
                <v-col
                  cols="8"
                  sm="8"
                  md="8"
                  lg="9"
                >
                  <p>{{ `: ${item.value}` }}</p>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          v-if="isLecturer"
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
        >
          <h2>Daftar Dosen</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <validation-observer v-slot="{ invalid }">
            <v-form id="form-lecturer" @submit.prevent="submitLecturer">
              <v-row no-gutters class="general-form mt-4">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(formLecturer)"
                  :key="`hf${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <div class="d-flex justify-end">
                <v-btn 
                  outlined
                  :min-width="120"
                  large
                  class="mr-2"
                  @click="handleBack"
                >
                  KEMBALI
                </v-btn>
                <v-btn 
                  color="primary"
                  :min-width="120"
                  large
                  type="submit"
                  form="form-lecturer"
                  :disabled="invalid"
                >
                  KIRIM
                </v-btn>
              </div>
            </v-form>
          </validation-observer>
        </v-card>
        <v-card
          rounded="lg"
          class="pa-9 mt-4"
          :class="{'py-9 px-4': $vuetify.breakpoint.smAndDown}"
          v-else
        >
          <h2 class="mb-4">Daftar Wawancara</h2>
          <v-alert
            dismissible
            type="error"
            v-model="isShowAlert"
          >{{ alertMsg }}</v-alert>
          <p class="title-14 mb-2 mt-4">Tanggal Test dan Wawancara</p>
          <ckeditor v-model="intrField" :editor="editor" @ready="onReady"></ckeditor>
          <p class="title-14 mt-2 mb-2">Attachement File</p>
          <v-file-input
            outlined
            dense
            accept=".pdf, .docx"
            placeholder="Click here to select your file"
            @change="handleInputFile"
          ></v-file-input>
          <div class="d-flex justify-end mt-4">
            <v-btn 
              outlined
              :min-width="120"
              large
              class="mr-2"
              @click="handleBack"
            >
              KEMBALI
            </v-btn>
            <v-btn 
              color="primary"
              :min-width="120"
              large
              :disabled="!intrField"
              @click="submitInterview()"
            >
              KIRIM
            </v-btn>
          </div>
        </v-card>
        <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
        <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="submitData"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import { get } from 'dot-prop'
import { ValidationObserver, extend } from 'vee-validate'
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document"
import { text } from '@/apps/web/constants/text'
import { DETAIL } from '@/constants/pages'
import {
  API_DASH_GET_USER_LIST,
  API_DASH_GET_LECTURER_LIST,
  API_DASH_LECTURER_SEND,
  API_DASH_INTERVIEW_SUBMIT,
} from '@/constants/apis'
import DialogInfo from '@/components/common/DialogInfo'
import DialogConfirm from '@/components/common/DialogConfirm'
import RegistrationInput from '@/components/common/registrationInput'

import {
  lecturer
} from '@/apps/dashboard/utils/formContent'
import loadscreen from '@/utils/common/loadScreen'
import request from '@/utils/request'
export default {
  data () {
    return {
      editor: DecoupledEditor,
      isImgError: false,
      intrField: '',
      isShowAlert: false,
      alertMsg: '',
      infoSuccess: {
        title: 'dialog.success',
        desc: 'dialog.success.submit.form',
        btn: 'dialog.success.btn'
      },
      info: {
        desc: 'Anda yakin data yang anda masukan sudah benar?'
      },
      formLecturer: [],
      lecturerList: [],
      fileData: ''
    }
  },
  components: {
    DialogInfo,
    DialogConfirm,
    ValidationObserver,
    RegistrationInput
  },
  computed: {
    isLecturer () {
      return get(this.$route.query, 'type', 'lecturer') === 'lecturer'
    },
    profileImage () {
      const img = get(this.studentDetail, 'image', '@/assets/imgs/icon-profile.png')
      return this.isImgError ? this.defaultImg : img
    },
    studentDetail () {
      return get(this.$store.state, 'studentDetail', {})
    },
    status () {
      return get(this.$store.state, 'status', 'init')
    },
    detailList () {
      const prodi = get(this.studentDetail, 'program_study', '')
      return [{
        title: 'dash.student.detail.name',
        value: get(this.studentDetail, 'name', '')
      }, {
        title: 'dash.student.detail.email',
        value: get(this.studentDetail, 'email', '')
      }, {
        title: 'dash.student.detail.phone',
        value: get(this.studentDetail, 'phone', '')
      }, {
        title: 'dash.student.detail.prodi',
        value: text[`program.studi.${prodi}.title`]
      }, {
        title: 'dash.student.detail.status',
        value: get(this.studentDetail, 'status_name', '')
      }, {
        title: 'dash.student.detail.academic.year',
        value: get(this.studentDetail, 'season_name', '')
      }, {
        title: 'dash.student.detail.period',
        value: get(this.studentDetail, 'academic_year', '')
      }]
    },
    interviewDetail () {
      return get(this.$store.state, 'interview', '')
    },
  },
  created () {
    loadscreen.show()
    if (!this.studentDetail.name) {
      const params = {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }
      this.$store.dispatch('getDashboardCompletionForm', params).then(res => {
        if (!res.success) {
          this.$router.replace({
            name: DETAIL,
            query: {
              formId: params.entry_form_id
            }
          }).catch(()=>{})
        } else {
          setTimeout(() => {
            this.intrField = this.interviewDetail || ''
          }, 100)
        }
      })
    }
    loadscreen.hide()
  },
  mounted () {
    if (this.isLecturer) {
      this.formLecturer = this.getData(JSON.parse(JSON.stringify(lecturer)))
      request(API_DASH_GET_LECTURER_LIST, {
        entry_form_id: get(this.$route.query, 'formId', ''),
      }).then(res => {
        if (res.success) {
          this.formLecturer.map((itm, idx) => {
            if (itm.fields === `user_name_${idx+1}`) {
              itm.name = res[`user_name_${idx+1}`]
              itm.id = res[`user_id_${idx+1}`]
              const params = {...itm.params}
              params.name = itm.name
              itm.id && request(API_DASH_GET_USER_LIST, params).then(res => {
                if (res.success) {
                  res.list.map(item =>{
                    item.label = `${item.name} - ${item.email}`
                    if (item.id === itm.id) {
                      itm.value = item.label
                    }
                  })
                } else {
                  this.alertMsg = get(res, 'resultInfo.resultMsg', '')
                  this.isShowAlert = true
                }
              })
            } 
          })
        } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
      })
    } else {
      setTimeout(() => {
        this.intrField = this.interviewDetail || ''
      }, 100)
    }
  },
  methods: {
    onReady(editor) {
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        )
    },
    getData (item) {
      return item
    },
    separateColoum (list = []) {
      const median = Math.ceil(list.length/2)
      return [{
        list: list.slice(0, median)
      }, {
        list: list.slice(median, 10)
      }]
    },
    handleInput (e) {},
    handleBack () {
      this.$router.replace({
        name: DETAIL,
        query: {
          formId: get(this.$route.query, 'formId', ''),
        }
      }).catch(()=>{})
    },
    handleImage (event) {
      this.isImgError = true
    },
    submitData () {
      const params = {}
      let apiUrl = API_DASH_INTERVIEW_SUBMIT
      
      params.entry_form_id = get(this.$route.query, 'formId', '')
      if (this.isLecturer) {
        apiUrl = API_DASH_LECTURER_SEND
        this.formLecturer.map((itm, idx) => {
          if (itm.fields === `user_name_${idx+1}`) {
            params[`user_name_${idx+1}`] = itm.name
            params[`user_id_${idx+1}`] = itm.id
          } 
        })
        request(apiUrl, params).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
      } else {
        const formData = new FormData();
        formData.append("file", this.fileData);
        formData.append("interview", this.intrField);
        formData.append("entry_form_id", get(this.$route.query, 'formId', ''));
        request(apiUrl, formData, {content: 'multipart/form-data'}).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
        })
      }
    },
    submitInterview () {
      this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
    },
    submitLecturer () {
      this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
    },
    handleInputFile (e) {
      this.fileData = e
    }
  }
}
</script>

<style lang="scss" scoped>
.evaluation-setting {
  .column-btn {
    height: 100%;
  }
  .title-14 {
    font-size: 14px;
    font-weight: bold;
  }
  ::v-deep .ck-editor__editable_inline {
    min-height: 400px;
    border: 1px solid grey;
  }
}
</style>